import React, { useEffect, memo } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useDispatch, useSelector } from "react-redux";
import {
 fetchCategories,
 fetchSubCategories,
 fetchProducts,
 selectCategory,
 selectSubCategory,
} from "../../../redux/servicesSlice";
import "../../../styles/servicesPage.css";

// Define an array with the desired category order by name
const desiredCategoryOrder = [
 "Periodic Services",
 "AC Service & Repair",
 "Car Spa & Cleaning",
 "Detailing Services",
];
const categoryImages = [
 "/car-repair-svgrepo-com.svg", // Image for category 3
 "/auto-paint-service-svgrepo-com.svg", // Image for category 0
 "/car-washing-svgrepo-com.svg", // Image for category 1
 "/auto-mechanic-car-mechanic-car-repair-svgrepo-com.svg", // Image for category 2
];
// Reusable Category Card Component
const CategoryCard = memo(
 ({ category, selectedCategory, handleCategorySelect, index }) => {
  const isSelected = selectedCategory === category._id;

  return (
   <Card
    key={category._id}
    className="services_card"
    style={{
     background: isSelected
      ? "#cfdff4" // Gradient background for selected card
      : "#ffffff", // White background for non-selected card
     borderBottom: isSelected ? "8px solid #007bff" : "none",
     color: isSelected ? "#000000" : "#4299E1", // Change text color based on selection
    }}
    onClick={() => handleCategorySelect(category._id, index)}
   >
    <img
     src={categoryImages[index]}
     alt={category.categoryName}
     width="100"
     height="100"
     style={{
      filter: isSelected
       ? "none"
       : "invert(32%) sepia(93%) saturate(7500%) hue-rotate(175deg) brightness(93%) contrast(88%)", // Blue color for non-selected SVG
     }}
    />

    <h3>{category.categoryName}</h3>
   </Card>
  );
 }
);

const ServicesPage = () => {
 const dispatch = useDispatch();
 const {
  categories,
  subCategories,
  products,
  loading,
  error,
  selectedCategory,
  selectedSubCategory,
 } = useSelector((state) => state.services);

 const [isMediumOrSmall] = useMediaQuery("(max-width: 992px)");

 // Fetch categories when the component mounts
 useEffect(() => {
  dispatch(fetchCategories());
 }, [dispatch]);

 // Handle category selection
 const handleCategorySelect = (categoryId, index) => {
  dispatch(selectCategory(categoryId));
  dispatch(fetchSubCategories(categoryId));
 };

 // Handle subcategory selection
 const handleSubCategorySelect = (subCategoryId) => {
  dispatch(selectSubCategory(subCategoryId));
  dispatch(fetchProducts(subCategoryId));
 };

 // Check if a subcategory has products
 const hasProductsForSubCategory = (subCategoryId) => {
  return products[subCategoryId]?.length > 0;
 };

 // Create a copy of the categories array and sort it based on the desired order
 const orderedCategories = [...categories].sort((a, b) => {
  return (
   desiredCategoryOrder.indexOf(a.categoryName.trim()) -
   desiredCategoryOrder.indexOf(b.categoryName.trim())
  );
 });

 return (
  <div className="services_background">
   <section>
    <Container>
     <Row className="services_heading">Services we provide</Row>

     {/* Conditionally render the Carousel only for medium and small screens */}
     {isMediumOrSmall ? (
      <Row>
       <Col className="services_cardsAll">
        <Carousel
         showArrows={true}
         showThumbs={false}
         showStatus={false}
         infiniteLoop={true}
         autoPlay={false}
         swipeable={true}
         emulateTouch={true}
         useKeyboardArrows={true}
         centerMode={true}
         centerSlidePercentage={40.33}
        >
         {orderedCategories.map((category, index) => (
          <CategoryCard
           key={category._id}
           category={category}
           selectedCategory={selectedCategory}
           handleCategorySelect={handleCategorySelect}
           index={index}
          />
         ))}
        </Carousel>
       </Col>
      </Row>
     ) : (
      <Row>
       {orderedCategories.map((category, index) => (
        <Col key={category._id} md="3">
         <CategoryCard
          category={category}
          selectedCategory={selectedCategory}
          handleCategorySelect={handleCategorySelect}
          index={index}
         />
        </Col>
       ))}
      </Row>
     )}

     {/* Render subcategories if a category is selected */}
     {selectedCategory && (
      <Row>
       <Col className="services_cardDetailAll">
        {subCategories[selectedCategory]?.length > 0 ? (
         <ul className="subcategory_list">
          {subCategories[selectedCategory].map((subCategory) => (
           <li
            key={subCategory._id}
            className={`subcategory_item ${
             selectedSubCategory === subCategory._id ? "selected" : ""
            }`}
            onClick={() => handleSubCategorySelect(subCategory._id)}
           >
            <Button
             className="subcategory-button"
             disabled={!hasProductsForSubCategory(subCategory._id)}
            >
             {subCategory.subCategoryName}
            </Button>
           </li>
          ))}
         </ul>
        ) : (
         <Text>No subcategories available for this category</Text>
        )}
       </Col>
      </Row>
     )}

     {/* Render products related to the selected subcategory */}
     {selectedSubCategory && products[selectedSubCategory]?.length > 0 && (
      <Row>
       {products[selectedSubCategory].map((product) => (
        <Col md="6" sm="12" className="mb-4" key={product._id}>
         <Card className="service-card">
          <Row>
           {/* Product details section */}
           <Col md="8" className="service-details">
            <div className="service-header">
             <h3>{product.productName}</h3>
             {product.offerTag && product.offerTag.length > 0 && (
              <div className="service-discount">
               {product.offerTag[0].value}
              </div>
             )}
            </div>
            <ul className="service-info">
             <li>
              <i className="ri-time-line"></i> Takes{" "}
              {product.highlights?.[0]?.text || "N/A"}
             </li>
             <li>
              <i className="ri-list-check"></i> Includes{" "}
              {product.includedService?.length || "0"} services
             </li>
             <li>
              <a href="#details" className="service-details-link">
               See details
              </a>
             </li>
            </ul>
            <div className="service-price">
             <p>
              <span className="crossed-price">₹{product.dummyPriceMrp}/-</span>{" "}
              (MRP: ₹{product.dummyPriceActual}/-)
             </p>
             <Button className="check-price-btn">CHECK PRICE</Button>
            </div>
           </Col>

           {/* Product Image Section */}
           <Col md="4" className="service-image-container">
            <img
             src={product.productImage}
             alt={product.productName}
             className="service-image"
            />
           </Col>
          </Row>
         </Card>
        </Col>
       ))}
      </Row>
     )}
    </Container>
   </section>
  </div>
 );
};

export default ServicesPage;

// import React, { useEffect, memo } from "react";
// import { Button, Card, Col, Container, Row } from "reactstrap";
// import { Box, Text, useMediaQuery } from "@chakra-ui/react";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { useDispatch, useSelector } from "react-redux";
// import {
//  fetchCategories,
//  fetchSubCategories,
//  fetchProducts,
//  selectCategory,
//  selectSubCategory,
// } from "../../../redux/servicesSlice";
// import "../../../styles/servicesPage.css";
// // import "../../styles/servicesPage.css";
// const categoryImages = [
//  "/auto-paint-service-svgrepo-com.svg", // Image for category 0
//  "/car-washing-svgrepo-com.svg", // Image for category 1
//  "/auto-mechanic-car-mechanic-car-repair-svgrepo-com.svg", // Image for category 2
//  "/car-repair-svgrepo-com.svg", // Image for category 3
// ];

// // Define an array of SVG image paths (you can add more as needed)

// // Define the correct order of categories based on your requirements
// const categoryOrder = [
//  { name: "Periodic Services", index: 3 },
//  { name: "AC Service & Repair", index: 2 },
//  { name: "Car Spa & Cleaning", index: 1 },
//  { name: "Detailing Services", index: 0 },
// ];

// const CategoryCard = memo(
//  ({ category, selectedCategory, handleCategorySelect, index }) => {
//   const isSelected = selectedCategory === category._id;

//   return (
//    <Card
//     key={category._id}
//     className="services_card"
//     style={{
//      background: isSelected
//       ? "linear-gradient(to right, #4299E1, #9F7AEA)" // Gradient background for selected card
//       : "#ffffff", // White background for non-selected card
//      border: isSelected ? "2px solid #007bff" : "none",
//      color: isSelected ? "#000000" : "#4299E1", // Change text color based on selection
//     }}
//     onClick={() => handleCategorySelect(category._id, index)}
//    >
//     {/* Render the SVG image with a color change if not selected */}
//     <img
//      src={categoryImages[index]}
//      alt={category.categoryName}
//      width="100"
//      height="100"
//      style={{
//       filter: isSelected
//        ? "none"
//        : "invert(32%) sepia(93%) saturate(7500%) hue-rotate(175deg) brightness(93%) contrast(88%)", // Blue color for non-selected SVG
//      }}
//     />
//     <h3>{category.categoryName}</h3>
//    </Card>
//   );
//  }
// );

// const ServicesPage = () => {
//  const dispatch = useDispatch();
//  const {
//   categories,
//   subCategories,
//   products,
//   loading,
//   error,
//   selectedCategory,
//   selectedSubCategory,
//  } = useSelector((state) => state.services);

//  const [isMediumOrSmall] = useMediaQuery("(max-width: 992px)");

//  // Fetch categories when the component mounts and select the default category and subcategory
//  useEffect(() => {
//   const loadDefaultCategoryAndSubCategory = async (categories) => {
//    // Find "Periodic Services" category
//    const defaultCategory = categories.find(
//     (category) => category.categoryName === "Periodic Services"
//    );

//    if (defaultCategory) {
//     // Dispatch category selection
//     dispatch(selectCategory(defaultCategory._id));

//     // Fetch subcategories for the selected category
//     const subCategoryResult = await dispatch(
//      fetchSubCategories(defaultCategory._id)
//     );

//     // Find "Scheduled Packages" subcategory
//     const subCategoriesData = subCategoryResult.payload;
//     const defaultSubCategory = subCategoriesData.find(
//      (subCategory) => subCategory.subCategoryName === "Scheduled Packages"
//     );

//     if (defaultSubCategory) {
//      // Dispatch subcategory selection and fetch products for the subcategory
//      dispatch(selectSubCategory(defaultSubCategory._id));
//      dispatch(fetchProducts(defaultSubCategory._id));
//     }
//    }
//   };

//   // Fetch categories only once when the component mounts
//   const fetchInitialData = async () => {
//    const resultAction = await dispatch(fetchCategories());
//    if (fetchCategories.fulfilled.match(resultAction)) {
//     const categoriesData = resultAction.payload; // Extract categories from response
//     loadDefaultCategoryAndSubCategory(categoriesData);
//    }
//   };

//   fetchInitialData();
//  }, [dispatch]);

//  // Handle category selection
//  const handleCategorySelect = (categoryId, index) => {
//   dispatch(selectCategory(categoryId));
//   dispatch(fetchSubCategories(categoryId));
//  };

//  // Handle subcategory selection
//  const handleSubCategorySelect = (subCategoryId) => {
//   dispatch(selectSubCategory(subCategoryId));
//   dispatch(fetchProducts(subCategoryId));
//  };

//  // Check if subcategory has products
//  const hasProductsForSubCategory = (subCategoryId) => {
//   return products[subCategoryId]?.length > 0;
//  };

//  return (
//   <div className="services_background">
//    <section>
//     <Container>
//      <Row className="services_heading">Services we provide</Row>

//      {/* Conditionally render the Carousel only for medium and small screens */}
//      {isMediumOrSmall ? (
//       <Row>
//        <Col className="services_cardsAll">
//         <Carousel
//          showArrows={true}
//          showThumbs={false}
//          showStatus={false}
//          infiniteLoop={true}
//          autoPlay={false}
//          swipeable={true}
//          emulateTouch={true}
//          useKeyboardArrows={true}
//          centerMode={true}
//          centerSlidePercentage={40.33}
//         >
//          {categories.map((category, index) => (
//           <CategoryCard
//            key={category._id}
//            category={category}
//            selectedCategory={selectedCategory}
//            handleCategorySelect={handleCategorySelect}
//            index={index}
//           />
//          ))}
//         </Carousel>
//        </Col>
//       </Row>
//      ) : (
//       <Row>
//        {categories.map((category, index) => (
//         <Col key={category._id} md="3">
//          <CategoryCard
//           category={category}
//           selectedCategory={selectedCategory}
//           handleCategorySelect={handleCategorySelect}
//           index={index}
//          />
//         </Col>
//        ))}
//       </Row>
//      )}

//      {/* Render subcategories if a category is selected */}
//      {selectedCategory && (
//       <Row>
//        <Col className="services_cardDetailAll">
//         {subCategories[selectedCategory]?.length > 0 ? (
//          <ul className="subcategory_list">
//           {subCategories[selectedCategory].map((subCategory) => (
//            <li
//             key={subCategory._id}
//             className={`subcategory_item ${
//              selectedSubCategory === subCategory._id ? "selected" : ""
//             }`}
//             onClick={() => handleSubCategorySelect(subCategory._id)}
//            >
//             <Button
//              className="subcategory-button"
//              disabled={!hasProductsForSubCategory(subCategory._id)}
//             >
//              {subCategory.subCategoryName}
//             </Button>
//            </li>
//           ))}
//          </ul>
//         ) : (
//          <Text>No subcategories available for this category</Text>
//         )}
//        </Col>
//       </Row>
//      )}

//      {/* Render products related to the selected subcategory */}
//      {selectedSubCategory && products[selectedSubCategory]?.length > 0 && (
//       <Row>
//        {products[selectedSubCategory].map((product) => (
//         <Col md="6" sm="12" className="mb-4" key={product._id}>
//          {/* Two cards per row on medium and larger screens */}
//          <Card className="service-card">
//           <Row>
//            {/* Product details section */}
//            <Col md="8" className="service-details">
//             <div className="service-header">
//              <h3>{product.productName}</h3>
//              {/* Assuming product might have an offer tag */}
//              {product.offerTag && product.offerTag.length > 0 && (
//               <div className="service-discount">
//                {product.offerTag[0].value}
//               </div>
//              )}
//             </div>
//             <ul className="service-info">
//              <li>
//               <i className="ri-time-line"></i> Takes{" "}
//               {product.highlights?.[0]?.text || "N/A"}
//              </li>
//              <li>
//               <i className="ri-list-check"></i> Includes{" "}
//               {product.includedService?.length || "0"} services
//              </li>
//              <li>
//               <a href="#details" className="service-details-link">
//                See details
//               </a>
//              </li>
//             </ul>
//             <div className="service-price">
//              <p>
//               <span className="crossed-price">₹{product.dummyPriceMrp}/-</span>{" "}
//               (MRP: ₹{product.dummyPriceActual}/-)
//              </p>
//              <Button className="check-price-btn">CHECK PRICE</Button>
//             </div>
//            </Col>

//            {/* Product Image Section */}
//            <Col md="4" className="service-image-container">
//             <img
//              src={product.productImage}
//              alt={product.productName}
//              className="service-image"
//             />
//            </Col>
//           </Row>
//          </Card>
//         </Col>
//        ))}
//       </Row>
//      )}
//     </Container>
//    </section>
//   </div>
//  );
// };

// export default ServicesPage;

// // // src/components/pages/ServicesPage.js
// // import React, { useEffect, memo } from "react";
// // import { Button, Card, Col, Container, Row } from "reactstrap";
// // import { Box, Text, useMediaQuery } from "@chakra-ui/react";
// // import { Carousel } from "react-responsive-carousel";
// // import "react-responsive-carousel/lib/styles/carousel.min.css";
// // import { useDispatch, useSelector } from "react-redux";
// // import {
// //  fetchCategories,
// //  fetchSubCategories,
// //  fetchProducts,
// //  selectCategory,
// //  selectSubCategory,
// // } from "../../redux/servicesSlice";
// // import "../../styles/servicesPage.css";

// // // Memoized CategoryCard component
// // const CategoryCard = memo(
// //  ({ category, selectedCategory, handleCategorySelect, index }) => (
// //   <Card
// //    key={category._id}
// //    className={`services_card ${
// //     selectedCategory === category._id ? "selected" : ""
// //    }`}
// //    onClick={() => handleCategorySelect(category._id, index)}
// //   >
// //    <i className="ri-roadster-fill cardIconSize"></i>
// //    {category.categoryName}
// //   </Card>
// //  )
// // );

// // const ServicesPage = () => {
// //  const dispatch = useDispatch();
// //  const {
// //   categories,
// //   subCategories,
// //   products,
// //   loading,
// //   error,
// //   selectedCategory,
// //   selectedSubCategory,
// //  } = useSelector((state) => state.services);
// //  const [isMediumOrSmall] = useMediaQuery("(max-width: 992px)");

// //  // Fetch categories when the component mounts
// //  useEffect(() => {
// //   dispatch(fetchCategories());
// //  }, [dispatch]);

// //  // Handle category selection
// //  const handleCategorySelect = (categoryId, index) => {
// //   dispatch(selectCategory(categoryId));
// //   dispatch(fetchSubCategories(categoryId));
// //  };

// //  // Handle subcategory selection
// //  const handleSubCategorySelect = (subCategoryId) => {
// //   dispatch(selectSubCategory(subCategoryId));
// //   dispatch(fetchProducts(subCategoryId));
// //  };

// //  // Check if subcategory has products
// //  const hasProductsForSubCategory = (subCategoryId) => {
// //   return products[subCategoryId]?.length > 0;
// //  };

// //  return (
// //   <div className="services_background">
// //    <section>
// //     <Container>
// //      <Row className="services_heading">Services we provide</Row>

// //      {/* Conditionally render the Carousel only for medium and small screens */}
// //      {isMediumOrSmall ? (
// //       <Row>
// //        <Col className="services_cardsAll">
// //         <Carousel
// //          showArrows={true}
// //          showThumbs={false}
// //          showStatus={false}
// //          infiniteLoop={true}
// //          autoPlay={false}
// //          swipeable={true}
// //          emulateTouch={true}
// //          useKeyboardArrows={true}
// //          centerMode={true}
// //          centerSlidePercentage={40.33}
// //         >
// //          {categories.map((category, index) => (
// //           <CategoryCard
// //            key={category._id}
// //            category={category}
// //            selectedCategory={selectedCategory}
// //            handleCategorySelect={handleCategorySelect}
// //            index={index}
// //           />
// //          ))}
// //         </Carousel>
// //        </Col>
// //       </Row>
// //      ) : (
// //       <Row>
// //        {categories.map((category, index) => (
// //         <Col key={category._id} md="3">
// //          <CategoryCard
// //           category={category}
// //           selectedCategory={selectedCategory}
// //           handleCategorySelect={handleCategorySelect}
// //           index={index}
// //          />
// //         </Col>
// //        ))}
// //       </Row>
// //      )}

// //      {/* Render subcategories if a category is selected */}
// //      {selectedCategory && (
// //       <Row>
// //        <Col className="services_cardDetailAll">
// //         {subCategories[selectedCategory]?.length > 0 ? (
// //          <ul className="subcategory_list">
// //           {subCategories[selectedCategory].map((subCategory) => (
// //            <li
// //             key={subCategory._id}
// //             className={`subcategory_item ${
// //              selectedSubCategory === subCategory._id ? "selected" : ""
// //             }`}
// //             onClick={() => handleSubCategorySelect(subCategory._id)}
// //            >
// //             <Button
// //              className="subcategory-button"
// //              disabled={!hasProductsForSubCategory(subCategory._id)}
// //             >
// //              {subCategory.subCategoryName}
// //             </Button>
// //            </li>
// //           ))}
// //          </ul>
// //         ) : (
// //          <Text>No subcategories available for this category</Text>
// //         )}
// //        </Col>
// //       </Row>
// //      )}

// //      {/* Render products related to the selected subcategory */}
// //      {selectedSubCategory && products[selectedSubCategory]?.length > 0 && (
// //       <Row>
// //        {products[selectedSubCategory].map((product) => (
// //         <Col md="6" sm="12" className="mb-4" key={product._id}>
// //          {/* Two cards per row on medium and larger screens */}
// //          <Card className="service-card">
// //           <Row>
// //            {/* Product details section */}
// //            <Col md="8" className="service-details">
// //             <div className="service-header">
// //              <h3>{product.productName}</h3>
// //              {/* Assuming product might have an offer tag */}
// //              {product.offerTag && product.offerTag.length > 0 && (
// //               <div className="service-discount">
// //                {product.offerTag[0].value}
// //               </div>
// //              )}
// //             </div>
// //             <ul className="service-info">
// //              <li>
// //               <i className="ri-time-line"></i> Takes{" "}
// //               {product.highlights?.[0]?.text || "N/A"}
// //              </li>
// //              <li>
// //               <i className="ri-list-check"></i> Includes{" "}
// //               {product.includedService?.length || "0"} services
// //              </li>
// //              <li>
// //               <a href="#details" className="service-details-link">
// //                See details
// //               </a>
// //              </li>
// //             </ul>
// //             <div className="service-price">
// //              {/* <p>
// //               Starting from ₹{product.dummyPriceMrp}/- (MRP: ₹
// //               {product.dummyPriceActual}/-)
// //              </p> */}
// //              <p>
// //               <span className="crossed-price">₹{product.dummyPriceMrp}/-</span>{" "}
// //               (MRP: ₹{product.dummyPriceActual}/-)
// //              </p>
// //              {/* <p>
// //               Starting from ₹{product.dummyPriceActual}/- (MRP: ₹
// //               {product.dummyPriceMrp}/-)
// //              </p> */}
// //              <Button className="check-price-btn">CHECK PRICE</Button>
// //             </div>
// //            </Col>

// //            {/* Product Image Section */}
// //            <Col md="4" className="service-image-container">
// //             <img
// //              src={product.productImage}
// //              alt={product.productName}
// //              className="service-image"
// //             />
// //            </Col>
// //           </Row>
// //          </Card>
// //         </Col>
// //        ))}
// //       </Row>
// //      )}
// //     </Container>
// //    </section>
// //   </div>
// //  );
// // };

// // export default ServicesPage;

// // // import React, { useState, useEffect, memo } from "react";
// // // import { Button, Card, Col, Container, Row } from "reactstrap";
// // // import axios from "axios";
// // // import "../../styles/servicesPage.css";
// // // import { Box, Text, useMediaQuery } from "@chakra-ui/react"; // Import useMediaQuery from Chakra UI
// // // import { Carousel } from "react-responsive-carousel";
// // // import "react-responsive-carousel/lib/styles/carousel.min.css";

// // // // Memoized CategoryCard component to prevent unnecessary re-renders
// // // const CategoryCard = memo(
// // //  ({ category, selectedCategory, handleCategorySelect, index }) => (
// // //   <Card
// // //    key={category._id}
// // //    className={`services_card ${
// // //     selectedCategory === category._id ? "selected" : ""
// // //    }`}
// // //    onClick={() => handleCategorySelect(category._id, index)}
// // //   >
// // //    <i className="ri-roadster-fill cardIconSize"></i>
// // //    {category.categoryName}
// // //   </Card>
// // //  )
// // // );

// // // const ServicesPage = () => {
// // //  const [products, setProducts] = useState([]); // State to store products
// // //  const [selectedItem, setSelectedItem] = useState(0); // Track the centered card
// // //  const [categories, setCategories] = useState([]); // Store categories
// // //  const [subCategories, setSubCategories] = useState([]); // Store subcategories from the API
// // //  const [services, setServices] = useState([]); // Store services for subcategory
// // //  const [selectedCategory, setSelectedCategory] = useState(null); // Track selected category
// // //  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // Track selected subcategory
// // //  const [loading, setLoading] = useState(true); // Track loading state
// // //  const [error, setError] = useState(null); // Track error state

// // //  // Use useMediaQuery hook to detect if the screen is medium or smaller
// // //  const [isMediumOrSmall] = useMediaQuery("(max-width: 992px)"); // Bootstrap's "lg" breakpoint is 992px

// // //  // Fetch categories on component mount
// // //  useEffect(() => {
// // //   const fetchCategories = async () => {
// // //    try {
// // //     const response = await axios.get(
// // //      "https://app-api.carexpert.org.in/api/categories/all"
// // //     );
// // //     setCategories(response.data);
// // //     setLoading(false);
// // //     // Automatically select the "Periodic Services" category and "Scheduled Packages" subcategory on load
// // //     const defaultCategory = response.data.find(
// // //      (category) => category.categoryName === "Periodic Services "
// // //     );
// // //     if (defaultCategory) {
// // //      setSelectedCategory(defaultCategory._id);
// // //      fetchSubCategories(defaultCategory._id, true);
// // //     }
// // //    } catch (err) {
// // //     setError("Failed to fetch categories");
// // //     setLoading(false);
// // //    }
// // //   };

// // //   fetchCategories();
// // //  }, []);

// // //  // Fetch subcategories when a category is selected
// // //  const fetchSubCategories = async (categoryId, isDefault = false) => {
// // //   try {
// // //    setLoading(true);
// // //    const response = await axios.get(
// // //     "https://app-api.carexpert.org.in/api/subcategories/all"
// // //    );
// // //    const filteredSubCategories = response.data.filter(
// // //     (subCat) => subCat.categoryId._id === categoryId
// // //    );
// // //    setSubCategories(filteredSubCategories);
// // //    setLoading(false);
// // //    if (isDefault) {
// // //     // Automatically select "Scheduled Packages" subcategory on load
// // //     const defaultSubCategory = filteredSubCategories.find(
// // //      (subCat) => subCat.subCategoryName === "Scheduled Packages"
// // //     );
// // //     if (defaultSubCategory) {
// // //      setSelectedSubCategory(defaultSubCategory);
// // //      handleSubCategorySelect(defaultSubCategory._id);
// // //     }
// // //    }
// // //   } catch (err) {
// // //    setError("Failed to fetch subcategories");
// // //    setLoading(false);
// // //   }
// // //  };

// // //  // Handle category selection
// // //  const handleCategorySelect = (categoryId, index) => {
// // //   if (categoryId === selectedCategory && selectedItem === index) return;

// // //   setSelectedCategory(categoryId);
// // //   setSelectedItem(index);
// // //   setSelectedSubCategory(null);
// // //   setServices([]);
// // //   fetchSubCategories(categoryId);
// // //  };

// // //  // Handle subcategory selection and fetch associated products
// // //  const handleSubCategorySelect = async (subCategoryId) => {
// // //   const subCategory = subCategories.find((sub) => sub._id === subCategoryId);
// // //   setSelectedSubCategory(subCategory);
// // //   setServices(subCategory?.services || []);

// // //   try {
// // //    // Fetch products from API
// // //    const response = await axios.get(
// // //     "https://app-api.carexpert.org.in/api/products"
// // //    );
// // //    const filteredProducts = response.data.filter(
// // //     (product) => product.subCategoryId._id === subCategoryId
// // //    );
// // //    setProducts(filteredProducts);
// // //   } catch (err) {
// // //    console.error("Failed to fetch products", err);
// // //    setError("Failed to fetch products");
// // //   }
// // //  };

// // //  // Check if a subcategory has products
// // //  const hasProductsForSubCategory = (subCategoryId) => {
// // //   return products.some(
// // //    (product) => product.subCategoryId._id === subCategoryId
// // //   );
// // //  };

// // //  if (loading) {
// // //   return <div>Loading...</div>;
// // //  }

// // //  if (error) {
// // //   return <div>{error}</div>; // Show error message
// // //  }

// // //  return (
// // //   <div className="services_background">
// // //    <section>
// // //     <Container>
// // //      <Row className="services_heading">Services we provide</Row>

// // //      {/* Conditionally render the Carousel only for medium and small screens */}
// // //      {isMediumOrSmall ? (
// // //       <Row>
// // //        <Col className="services_cardsAll">
// // //         <Carousel
// // //          showArrows={true}
// // //          showThumbs={false}
// // //          showStatus={false}
// // //          infiniteLoop={true}
// // //          autoPlay={false}
// // //          swipeable={true}
// // //          emulateTouch={true}
// // //          useKeyboardArrows={true}
// // //          centerMode={true}
// // //          centerSlidePercentage={40.33}
// // //          selectedItem={selectedItem} // Center the selected item
// // //          onChange={(index) => setSelectedItem(index)} // Handle carousel changes
// // //         >
// // //          {categories.map((category, index) => (
// // //           <CategoryCard
// // //            key={category._id}
// // //            category={category}
// // //            selectedCategory={selectedCategory}
// // //            handleCategorySelect={handleCategorySelect}
// // //            index={index}
// // //           />
// // //          ))}
// // //         </Carousel>
// // //        </Col>
// // //       </Row>
// // //      ) : (
// // //       <Row>
// // //        {categories.map((category, index) => (
// // //         <Col key={category._id} md="3">
// // //          <CategoryCard
// // //           category={category}
// // //           selectedCategory={selectedCategory}
// // //           handleCategorySelect={handleCategorySelect}
// // //           index={index}
// // //          />
// // //         </Col>
// // //        ))}
// // //       </Row>
// // //      )}

// // //      {/* Render subcategories if a category is selected */}
// // //      {selectedCategory && (
// // //       <Row>
// // //        <Col className="services_cardDetailAll">
// // //         {subCategories.length > 0 ? (
// // //          <ul className="subcategory_list">
// // //           {subCategories.map((subCategory) => (
// // //            <li
// // //             key={subCategory._id}
// // //             className={`subcategory_item ${
// // //              selectedSubCategory?._id === subCategory._id ? "selected" : ""
// // //             }`}
// // //             onClick={() =>
// // //              hasProductsForSubCategory(subCategory._id) &&
// // //              handleSubCategorySelect(subCategory._id)
// // //             } // Disable button if no products
// // //            >
// // //             <Button
// // //              className="subcategory-button"
// // //              disabled={!hasProductsForSubCategory(subCategory._id)} // Disable if no products
// // //             >
// // //              {subCategory.subCategoryName}
// // //             </Button>
// // //            </li>
// // //           ))}
// // //          </ul>
// // //         ) : (
// // //          <Text>No subcategories available for this category</Text>
// // //         )}
// // //        </Col>
// // //       </Row>
// // //      )}

// // //      {/* Render products related to the selected subcategory */}
// // //      {selectedSubCategory && products.length > 0 && (
// // //       <Row>
// // //        {products.map((product) => (
// // //         <Col md="6" sm="12" className="mb-4" key={product._id}>
// // //          {/* Two cards per row on medium and larger screens */}
// // //          <Card className="service-card">
// // //           <Row>
// // //            {/* Product details section */}
// // //            <Col md="8" className="service-details">
// // //             <div className="service-header">
// // //              <h3>{product.productName}</h3>
// // //              {/* Assuming product might have an offer tag */}
// // //              {product.offerTag && product.offerTag.length > 0 && (
// // //               <div className="service-discount">
// // //                {product.offerTag[0].value}
// // //               </div>
// // //              )}
// // //             </div>
// // //             <ul className="service-info">
// // //              <li>
// // //               <i className="ri-time-line"></i> Takes{" "}
// // //               {product.highlights?.[0]?.text || "N/A"}
// // //              </li>
// // //              <li>
// // //               <i className="ri-list-check"></i> Includes{" "}
// // //               {product.includedService?.length || "0"} services
// // //              </li>
// // //              <li>
// // //               <a href="#details" className="service-details-link">
// // //                See details
// // //               </a>
// // //              </li>
// // //             </ul>
// // //             <div className="service-price">
// // //              {/* <p>
// // //               Starting from ₹{product.dummyPriceMrp}/- (MRP: ₹
// // //               {product.dummyPriceActual}/-)
// // //              </p> */}
// // //              <p>
// // //               <span className="crossed-price">₹{product.dummyPriceMrp}/-</span>{" "}
// // //               (MRP: ₹{product.dummyPriceActual}/-)
// // //              </p>
// // //              {/* <p>
// // //               Starting from ₹{product.dummyPriceActual}/- (MRP: ₹
// // //               {product.dummyPriceMrp}/-)
// // //              </p> */}
// // //              <Button className="check-price-btn">CHECK PRICE</Button>
// // //             </div>
// // //            </Col>

// // //            {/* Product Image Section */}
// // //            <Col md="4" className="service-image-container">
// // //             <img
// // //              src={product.productImage}
// // //              alt={product.productName}
// // //              className="service-image"
// // //             />
// // //            </Col>
// // //           </Row>
// // //          </Card>
// // //         </Col>
// // //        ))}
// // //       </Row>
// // //      )}
// // //     </Container>
// // //    </section>
// // //   </div>
// // //  );
// // // };

// // // export default ServicesPage;
