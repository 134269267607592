import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
 Box,
 Button,
 Container,
 Grid,
 Stack,
 Heading,
 Text,
} from "@chakra-ui/react";
import axios from "axios";
import WhyCarExpertPage from "../pages/WhyCarExpertPage";

const HeroSlider = () => {
 const [slides, setSlides] = useState([]);

 const settings = {
  fade: true,
  speed: 2000,
  autoplaySpeed: 3000,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
 };

 // 1024*500 size

 // Fetch data from the API
 useEffect(() => {
  const getBanners = async () => {
   try {
    const response = await axios.get(
     "https://app-api.carexpert.org.in/api/offers"
    );
    setSlides(response.data.offers);
   } catch (error) {
    console.error("Error fetching slides:", error);
   }
  };
  getBanners();
 }, []);

 // Ensure slides are loaded before rendering
 //  if (!slides || slides.length === 0) {
 //   return <div>Loading...</div>; // You can also render a loader or placeholder
 //  }

 return (
  <Box>
   {/* Desktop layout: 70% Hero Image and 30% Sidebar */}
   <Grid
    height="95vh"
    templateColumns={{ base: "1fr", md: "65% 34%" }} // Mobile: 100% width, Desktop: 70% for image, 30% for sidebar
    gap={4}
   >
    <Box position="relative">
     <Slider {...settings}>
      {slides.map((slide, index) => (
       <Box key={index} position="relative">
        <img
         src={"/test.webp"}
         //  srcSet={`${"/test.webp"}?w=800 800w, ${"/test.webp"}?w=1280 1280w, ${"/test.webp"}?w=1920 1920w`}
         srcSet={`${slide.imageUrl}?w=800 800w, ${slide.imageUrl}?w=1280 1280w, ${slide.imageUrl}?w=1920 1920w`}
         sizes="(max-width: 600px) 800px, (max-width: 1200px) 1280px, 1920px"
         alt={slide.title}
         style={{ width: "100%", height: "90vh", objectFit: "cover" }}
        />

        <Box
         position="absolute"
         top={{ base: "50%", md: "50%" }}
         left={{ base: "40%", md: "300px" }}
         transform="translate(-50%, -50%)" // Center the content on both mobile and desktop
         display="flex"
         flexDirection="column"
         justifyContent="center"
         alignItems="center"
         bg="rgba(0, 0, 0, 0.4)"
         color="white"
         width={{ base: "90%", md: "400px" }} // Responsive width
         padding="20px" // Add some padding for better spacing
        >
         <Stack spacing={4} textAlign="center">
          <Heading size="xl">{slide.title}</Heading>
          <Text fontSize="lg">{slide.description}</Text>
          <Text fontSize="lg" fontWeight="bold">
           All Services 10% OFF
          </Text>
          <Text fontSize="sm">Code: TRUHOLI</Text>
          <Button colorScheme="blue" size="lg">
           BOOK A SERVICE
          </Button>
         </Stack>
        </Box>
       </Box>
      ))}
     </Slider>
    </Box>

    {/* 30% - Sidebar (Features, Ratings, Car Brands, Models) */}
    <Box display="flex" flexDirection="column" justifyContent="space-between">
     <WhyCarExpertPage />
    </Box>
   </Grid>
  </Box>

  //   <Box>
  //    {/* Desktop layout: 70% Hero Image and 30% Sidebar */}
  //    <Grid
  //     height={"95vh"}
  //     templateColumns={{ base: "1fr", md: "70% 28%" }} // Mobile: 100% width, Desktop: 70% for image, 30% for sidebar
  //     gap={4}
  //    >
  //     <Box>
  //      <Slider {...settings}>
  //       {slides.map((slide, index) => (
  //        <Box key={index} position="relative">
  //         <img
  //          src={slide.imageUrl}
  //          alt={slide.title}
  //          style={{ width: "100%", height: "90vh", objectFit: "cover" }}
  //         />
  //         <Box
  //          position="absolute"
  //          top="100"
  //          left="150"
  //          right="0"
  //          display="flex"
  //          flexDirection="column"
  //          justifyContent="center"
  //          alignItems="center"
  //          bg="rgba(0, 0, 0, 0.4)"
  //          color="white"
  //          width="400px"
  //         >
  //          <Stack spacing={4} textAlign="center">
  //           <Heading size="xl">{slide.title}</Heading>
  //           <Text fontSize="lg">{slide.description}</Text>
  //           <Text fontSize="lg" fontWeight="bold">
  //            All Services 10% OFF
  //           </Text>
  //           <Text fontSize="sm">Code: TRUHOLI</Text>
  //           <Button colorScheme="blue" size="lg">
  //            BOOK A SERVICE
  //           </Button>
  //          </Stack>
  //         </Box>
  //        </Box>
  //       ))}
  //      </Slider>
  //     </Box>

  //     {/* 30% - Sidebar (Features, Ratings, Car Brands, Models) */}
  //     <Box display="flex" flexDirection="column" justifyContent="space-between">
  //      <WhyCarExpertPage />
  //     </Box>
  //    </Grid>
  //   </Box>
 );
};

export default HeroSlider;
