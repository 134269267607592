import { Fragment } from "react";
import Header from "./Components/Header/Header";
import Routers from "./routers/Routers";
import Footer from "./Components/Footer/Footer";
import { Box } from "@chakra-ui/react";
import FormComponent from "./FormComponent";

function App() {
 return (
  <Box border={"1px solid red"}>
   <Header />
   {/* <div className="content-container"> */}
   <Routers />
   <Footer />
  </Box>
 );
}

export default App;
