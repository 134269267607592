import React, { useRef } from "react";
import HeroSlider from "../UI/HeroSlider";
import Helmet from "../Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
// import FindCarForm from "../UI/FindCarForm";
// import "../../styles/find-car-form.css";
import AboutSection from "../UI/AboutSection";
import { useSelector } from "react-redux";
import { Box, Heading, IconButton, Image, Text } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import TrandingProducts from "../UI/TrandingProducts";
import OriginalSpareParts from "../UI/OriginalSpareParts";
import ServicesPage from "../UI/ServiceSection/ServicesPage";

const Home = () => {
 const servicesRef = useRef(null); // Ensure this ref is created properly
 const { models, selectedCar, loading, error } = useSelector(
  (state) => state.models
 );

 return (
  <div>
   <Helmet title="Home">
    <HeroSlider />
    {/* ServicesPage with ref */}
    <div ref={servicesRef}>
     <ServicesPage />
    </div>
    <TrandingProducts />
    <OriginalSpareParts />
    <AboutSection />
   </Helmet>
  </div>
 );
};

export default Home;
