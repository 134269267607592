import React, { useState, useEffect, useMemo } from "react";
import { Flex, Box, Image, Heading, Spinner, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrands } from "../../redux/brandsSlice";
import { fetchModelsByBrand, findCarByDetails } from "../../redux/modelsSlice";
import BrandModal from "../CarSelection/BrandModal";
import FuelModal from "../CarSelection/FuelModal";
import { ModelModal } from "../CarSelection/ModelModal";
import TransmissionModal from "../CarSelection/TransmissionModal";

// Reusable Component for the Selection Boxes with hover effect
const SelectionBox = ({ heading, imageSrc, onClick, disabled, loading }) => (
 <Box
  padding={5}
  boxShadow="lg"
  bg="gray.100"
  borderRadius="lg"
  _hover={{ bg: "gray.200", cursor: disabled ? "not-allowed" : "pointer" }}
  opacity={disabled ? 0.6 : 1}
  onClick={!disabled ? onClick : null}
 >
  <Heading as="h6" fontSize="15" textAlign={"left"}>
   {heading}
  </Heading>
  <Flex align="center" justify="center">
   {loading ? (
    <Spinner size="md" />
   ) : (
    <Image boxSize="40px" src={imageSrc} alt={`Select ${heading}`} />
   )}
  </Flex>
 </Box>
);

const FindCarForm = ({ servicesRef }) => {
 const dispatch = useDispatch();

 // Redux state for brands, models, selected car
 const {
  brands,
  loading: brandsLoading,
  error: brandsError,
 } = useSelector((state) => state.brands);
 const {
  models,
  loading: modelsLoading,
  error: modelsError,
 } = useSelector((state) => state.models);

 // Consolidated state for modal visibility
 const [modals, setModals] = useState({
  brand: false,
  model: false,
  fuel: false,
  transmission: false,
 });

 // Selected values
 const [selectedBrand, setSelectedBrand] = useState(null);
 const [selectedModel, setSelectedModel] = useState(null);
 const [selectedFuel, setSelectedFuel] = useState(null);
 const [selectedTransmission, setSelectedTransmission] = useState(null);
 const [scrollTriggered, setScrollTriggered] = useState(false);

 // Derived state for available fuel and transmission types
 const availableFuelTypes = useMemo(() => {
  const fuelTypesSet = new Set();
  models.forEach((model) =>
   model.fuelTypes.forEach((fuelType) => fuelTypesSet.add(fuelType))
  );
  return Array.from(fuelTypesSet);
 }, [models]);

 const availableTransmissionTypes = useMemo(() => {
  const transmissionTypesSet = new Set();
  models.forEach((model) =>
   model.transmissionTypes.forEach((transmissionType) =>
    transmissionTypesSet.add(transmissionType)
   )
  );
  return Array.from(transmissionTypesSet);
 }, [models]);

 useEffect(() => {
  // Fetch brands on load
  dispatch(fetchBrands());
 }, [dispatch]);

 // Handle selection flow
 const handleBrandSelect = async (brand) => {
  setSelectedBrand(brand);
  setModals((prev) => ({ ...prev, brand: false, model: true }));
  await dispatch(fetchModelsByBrand(brand._id));
 };

 const handleModelSelect = (model) => {
  setSelectedModel(model);
  setModals((prev) => ({ ...prev, model: false, transmission: true }));
 };

 const handleTransmissionSelect = (transmission) => {
  setSelectedTransmission(transmission);
  setModals((prev) => ({ ...prev, transmission: false, fuel: true }));
 };

 const handleFuelSelect = (fuel) => {
  setSelectedFuel(fuel);
  setModals((prev) => ({ ...prev, fuel: false }));

  // Dispatch the action to find car
  dispatch(
   findCarByDetails({
    brand: selectedBrand._id,
    title: selectedModel.title,
    fuelType: fuel,
    transmissionType: selectedTransmission,
   })
  );

  // Scroll to services section
  if (servicesRef && servicesRef.current && !scrollTriggered) {
   setTimeout(() => {
    servicesRef.current.scrollIntoView({
     behavior: "smooth",
     block: "center",
    });
    setScrollTriggered(true);
   }, 300);
  }
 };

 return (
  <Flex gap={5} mt={5}>
   {/* Show error if any fetching error occurs */}
   {brandsError && <Text color="red.500">Failed to load brands.</Text>}
   {modelsError && <Text color="red.500">Failed to load models.</Text>}

   {/* Selection Boxes with loading and error handling */}
   <SelectionBox
    heading="Select Brand"
    imageSrc="/brand.png"
    onClick={() => setModals((prev) => ({ ...prev, brand: true }))}
    loading={brandsLoading}
    disabled={brandsError}
   />
   <SelectionBox
    heading="Select Model"
    imageSrc="/car.png"
    onClick={() => setModals((prev) => ({ ...prev, model: true }))}
    loading={modelsLoading}
    disabled={!selectedBrand || modelsError}
   />
   <SelectionBox
    heading="Select Fuel"
    imageSrc="/energy.png"
    onClick={() => setModals((prev) => ({ ...prev, fuel: true }))}
    disabled={!selectedModel}
   />

   {/* Modals */}
   <BrandModal
    isOpen={modals.brand}
    onClose={() => setModals((prev) => ({ ...prev, brand: false }))}
    brands={brands}
    onSelect={handleBrandSelect}
   />
   <ModelModal
    isOpen={modals.model}
    onClose={() => setModals((prev) => ({ ...prev, model: false }))}
    models={models}
    onSelect={handleModelSelect}
   />
   <TransmissionModal
    isOpen={modals.transmission}
    onClose={() => setModals((prev) => ({ ...prev, transmission: false }))}
    transmissions={availableTransmissionTypes}
    onSelect={handleTransmissionSelect}
   />
   <FuelModal
    isOpen={modals.fuel}
    onClose={() => setModals((prev) => ({ ...prev, fuel: false }))}
    fuels={availableFuelTypes}
    onSelect={handleFuelSelect}
   />
  </Flex>
 );
};

export default FindCarForm;
