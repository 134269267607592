import React, { useEffect } from "react";
import Slider from "react-slick";
import { Box, Heading, Text, Image, SimpleGrid } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrendingProducts } from "../../redux/tranding";

function MultipleItems() {
 const dispatch = useDispatch();
 const { trendingProducts, loading, error } = useSelector(
  (state) => state.trending
 );

 useEffect(() => {
  dispatch(fetchTrendingProducts());
 }, [dispatch]);

 const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of products to show in a row
  slidesToScroll: 3,
  responsive: [
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 3,
     slidesToScroll: 3,
    },
   },
   {
    breakpoint: 768,
    settings: {
     slidesToShow: 2,
     slidesToScroll: 2,
    },
   },
   {
    breakpoint: 480,
    settings: {
     slidesToShow: 1,
     slidesToScroll: 1,
    },
   },
  ],
 };

 return (
  <Box maxW="100%" mx="auto" p={4}>
   {/* Chakra UI SimpleGrid for better layout control */}
   <SimpleGrid
    columns={{ base: 1, sm: 2, md: 3 }} // Change column count based on screen size
    spacing={6}
    mx="auto"
    maxW="1200px"
   >
    {trendingProducts.map((product) => (
     <Box
      key={product.id}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={4}
      boxShadow="md"
      textAlign="center"
     >
      <Image
       src={product.productImage}
       alt={product.productName}
       mb={4}
       boxSize="200px"
       objectFit="cover"
      />
      <Heading as="h3" size="md" mb={2}>
       {product.productName}
      </Heading>
      <Text fontSize="lg" fontWeight="bold">
       {product.dummyPriceActual}
      </Text>
     </Box>
    ))}
   </SimpleGrid>
  </Box>
 );
}

export default MultipleItems;

// import React, { useEffect } from "react";
// import Slider from "react-slick";
// import { Box, Heading, Text, Image } from "@chakra-ui/react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchTrendingProducts } from "../../redux/tranding";

// function MultipleItems() {
//  const dispatch = useDispatch();
//  const { trendingProducts, loading, error } = useSelector(
//   (state) => state.trending
//  );

//  useEffect(() => {
//   dispatch(fetchTrendingProducts());
//  }, [dispatch]);

//  const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 3,
//   responsive: [
//    {
//     breakpoint: 1024,
//     settings: {
//      slidesToShow: 3,
//      slidesToScroll: 3,
//     },
//    },
//    {
//     breakpoint: 768,
//     settings: {
//      slidesToShow: 2,
//      slidesToScroll: 2,
//     },
//    },
//    {
//     breakpoint: 480,
//     settings: {
//      slidesToShow: 1,
//      slidesToScroll: 1,
//     },
//    },
//   ],
//  };

//  // Example product data (you can replace this with dynamic data from an API)

//  return (
//   <div className="slider-container">
//    <Slider {...settings}>
//     {trendingProducts.map((product) => (
//      <Box
//       key={product.id}
//       borderWidth="1px"
//       borderRadius="lg"
//       overflow="hidden"
//       p={4}
//       boxShadow="md"
//       textAlign="center"
//      >
//       <Image src={product.productImage} alt={product.productName} mb={4} />
//       <Heading as="h3" size="md" mb={2}>
//        {product.productName}
//       </Heading>
//       <Text fontSize="lg" fontWeight="bold">
//        {product.dummyPriceActual}
//       </Text>
//      </Box>
//     ))}
//    </Slider>
//   </div>
//  );
// }

// export default MultipleItems;
