import React from "react";
import { Container } from "reactstrap";
import { Heading, Text } from "@chakra-ui/react";
import MultipleItems from "./MultipleItems"; // Import the carousel component

const TrandingProducts = () => {
 return (
  <section>
   <Container>
    <Heading textAlign="center" mb={6}>
     Trending Products
    </Heading>
    <Text textAlign={"center"}>Most booked service around you</Text>
    {/* Carousel component */}
    <MultipleItems />
   </Container>
  </section>
 );
};

export default TrandingProducts;
