import React, { useState, useEffect } from "react";
import {
 Box,
 Flex,
 HStack,
 IconButton,
 Stack,
 useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "../../styles/header.css";

const navLinks = [
 {
  path: "/",
  display: "Home",
 },
 {
  path: "/service",
  display: "Services",
 },
 {
  path: "/about",
  display: "About",
 },
 {
  path: "/Franchisee",
  display: "Become A Franchise Partner",
 },
 {
  path: "/contact",
  display: "Contact",
 },
];

const NavLink = ({ path, display }) => {
 const location = useLocation();
 const isActive = location.pathname === path;

 return (
  <Box
   as={RouterLink}
   to={path}
   px={2}
   py={1}
   rounded={"md"}
   _hover={{
    textDecoration: "none",
    color: "#F99950", // Hover color
   }}
   color={isActive ? "#F99950" : "white"} // Active link color
  >
   {display}
  </Box>
 );
};

export default function Header() {
 const { isOpen, onOpen, onClose } = useDisclosure();
 const [showHeader, setShowHeader] = useState(true); // State to toggle header visibility
 const [lastScrollTop, setLastScrollTop] = useState(0); // Track last scroll position

 return (
  <Box>
   <header className={`header ${showHeader ? "visible" : "hidden"}`}>
    <div className="header__top">
     <Container>
      <Row>
       <Col lg="6" md="6" sm="6">
        <div className="header__top__left">
         <span>Need Help?</span>
         <span className="header__top__help">
          <i className="ri-phone-fill"></i> Care@carexpert.org.in
         </span>
        </div>
       </Col>
       <Col lg="6" md="6" sm="6">
        <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
         <RouterLink to="#" className="d-flex align-items-center gap-1">
          <i className="ri-login-circle-line"></i> Login
         </RouterLink>
         <RouterLink to="/register" className="d-flex align-items-center gap-1">
          <i className="ri-user-add-line"></i> Register
         </RouterLink>
        </div>
       </Col>
      </Row>
     </Container>
    </div>

    <div className="header__middle">
     <Container>
      <Row>
       <Col lg="4" md="3" sm="4">
        <div className="logo">
         <h1>
          <RouterLink to="/" className="d-flex align-items-center gap-2">
           <i className="ri-roadster-fill"></i>
           <span>Car Expert</span>
          </RouterLink>
         </h1>
        </div>
       </Col>

       <Col lg="3" md="3" sm="4">
        <div className="header__location d-flex align-items-center gap-2">
         <span>
          <i className="ri-earth-line"></i>
         </span>
         <div className="header__location-content">
          <h4>India</h4>
          <h6>Noida, Bangalore</h6>
         </div>
        </div>
       </Col>

       <Col lg="3" md="3" sm="4">
        <div className="header__location d-flex align-items-center gap-2">
         <span>
          <i className="ri-time-line"></i>
         </span>
         <div className="header__location-content">
          <h4>Monday to Saturday</h4>
          <h6>9am - 7pm</h6>
         </div>
        </div>
       </Col>

       <Col
        lg="2"
        md="3"
        sm="0"
        className="d-flex align-items-center justify-content-end"
       >
        <button className="header__btn btn">
         <RouterLink to="/contact">
          <i className="ri-phone-fill"></i> Request a Call
         </RouterLink>
        </button>
       </Col>
      </Row>
     </Container>
    </div>
   </header>

   {/* Navbar Section */}
   <Box px={4} className="main__navbar">
    <Flex h={10} alignItems={"center"} justifyContent={"space-between"}>
     <IconButton
      size={"md"}
      icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
      aria-label={"Open Menu"}
      display={{ md: "none" }}
      onClick={isOpen ? onClose : onOpen}
     />
     <HStack spacing={8} alignItems={"center"} width={"70%"} margin={"auto"}>
      <HStack
       as={"nav"}
       spacing={4}
       display={{ base: "none", md: "flex" }}
       color={"white"}
      >
       {navLinks.map((link) => (
        <NavLink key={link.path} path={link.path} display={link.display} />
       ))}
      </HStack>
     </HStack>
    </Flex>

    {isOpen && (
     <Box pb={4} display={{ md: "none" }}>
      <Stack as={"nav"} spacing={4} color={"white"}>
       {navLinks.map((link) => (
        <NavLink key={link.path} path={link.path} display={link.display} />
       ))}
      </Stack>
     </Box>
    )}
   </Box>
  </Box>
 );
}
